<template>
  <div class="step-2-section-root" id="step-2-scroll-anchor">
    
      <div class="header">
          <div class="header-sub-left">
                <div ><img src="@/assets/images/logos/google-logo-350.png" alt="Google logo" style="max-width: 110px; background-color: rgba(255,255,255,0.2); border-radius: 50%; padding: 12px;"></div>
                <div class="label">Step Two:</div>
                <div class="sub-label">Don't forget about your Google Business Profile!</div>
                <div class="flavor-text"><b>DAS dealers experience over a 20% increase in mobile views on their Google Business Profile.</b> Your dedicated account manager ensures your profile stays active and optimized every month. According to Google data, businesses that consistently update their Google Business Profile experience improved local traffic when compared to those who take a “set it and forget it” approach.</div>
                <div class="disclaimer-text">* View full package details <a href="https://dasgm2025.com/new-google-business-profile/" target="_blank" style="text-decoration: none;">here</a>. Discounts applicable during SMRM enrollment.</div>
              </div>
            <div class="header-sub-right">2</div>
      </div>

      <span class="card-container-label" v-show="showPickOne">Choose One</span>

      <div class="cards" v-if="!isOnHighestGooglePackage">
        <PackageSectionCard :cardPackage="packages.googleBusinessProfileDominate" @click="packageSelected(packages.googleBusinessProfileDominate)"/>
        <PackageSectionCard :cardPackage="packages.googleBusinessProfileDefend" @click="packageSelected(packages.googleBusinessProfileDefend)"/>
      </div>

      <div class="good-to-go-message" v-else>
        <div class="good-to-go-message-content">
            You are already good to go here{{isOnHighestSMRMPackage ? ' too!' : '!'}}
        </div>
      </div>

        <p v-if="!isOnHighestGooglePackage">
            <input type="checkbox" id="google-decline" v-model="decline" />
            <label for="google-decline" style="color: white; font-size: 12px;">I decline the Google Business Profile and understand by opting out that I am forfeiting on behalf of the dealership up to $6,000 in annual savings.</label>
        </p>
  </div>
</template>

<script>
import PackageSectionCard from '@/components/PackageSectionCard.vue'
import { mapGetters } from 'vuex';

export default {
    components: {
        PackageSectionCard
    },
    computed: {
        ...mapGetters(['isOnHighestSMRMPackage', 'isOnHighestGooglePackage']),
        packages() {
            return this.$store.state.packages;
        },
        decline: {
            get() {
                return this.$store.state.googleOptOut;
            },
            set(value) {
                this.$store.commit('GOOGLE_OPT_OUT');
            }
      },
      showPickOne() {
            return this.$store.state.packagesToDisplay.filter(pkg => pkg.name.toUpperCase().includes("GOOGLE")).length > 1;
        }
    },
    methods: {
        packageSelected(pkg) {
            this.$store.commit('TOGGLE_GOOGLE_PACKAGE', pkg);
        },
    }
}
</script>

<style lang="scss" scoped>
#decline {
    margin-top: 15px;
}

.step-2-section-root {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
}

.header {
    display: flex;
    margin-top: 20px;
}

.header-sub-left {
    display: flex;
    flex-direction: column;
    width: 70%;
    row-gap: 10px;
}

.header-sub-right {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 30%;
    color: white;
    text-align: right;
    font-size: 150px;
    opacity: 0.5;
    font-weight: bold;
}

.label {
    font-size: 26px;
    font-weight: bold;
    color: white;
}

.sub-label {
    color: #FFF;
    font-weight: bold;
}

.flavor-text {
    color: white;
    font-size: 16px;
}

.disclaimer-text {
    color: white;
    font-size: 11px;
}

.card-container-label {
        color: white;
        font-size: 1rem;
        margin-top: 25px;
        font-weight: bold;
        user-select: none;
    }

.cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: max-content;
    row-gap: 20px;
    margin-top: 25px;
}

.good-to-go-message {
    position: relative;
    height: 400px;
    max-width: 600px;
    display: flex;
    justify-content:center;
    align-items: center;
    text-align: center;
    color: #2b2b2b;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 0px;
    box-sizing:border-box;
    text-align: center;
}

.good-to-go-message-content {
    font-size: 36px;
    // background-color: white;
    // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    margin-top: 30px;
    border: 5px solid #0096db;
    border-radius: 10px;
    padding: 10px;
}

.good-to-go-message:after {
    content: var(--content-text);
    position: absolute;
}

[type="checkbox"]:not(:checked),
  [type="checkbox"]:checked {
    position: absolute;
    left: 0;
    opacity: 0.01;
  }
  [type="checkbox"]:not(:checked) + label,
  [type="checkbox"]:checked + label {
    position: relative;
    padding-left: 2.3em;
    font-size: 1.05em;
    line-height: 1.7;
    cursor: pointer;
  }

  /* checkbox aspect */
  [type="checkbox"]:not(:checked) + label:before,
  [type="checkbox"]:checked + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 1.4em;
    height: 1.4em;
    border: 1px solid rgb(255, 255, 255);
    background: rgba(0,0,0,0.0);
    border-radius: .2em;
    box-shadow: inset 0 1px 3px rgba(0,0,0, .1), 0 0 0 rgba(203, 34, 237, .2);
    -webkit-transition: all .275s;
        transition: all .275s;
  }

  /* checked mark aspect */
  [type="checkbox"]:not(:checked) + label:after,
  [type="checkbox"]:checked + label:after {
    content: '✕';
    position: absolute;
    top: .525em;
    left: .18em;
    font-size: 1.375em;
    color: #FFF;
    line-height: 0;
    -webkit-transition: all .2s;
        transition: all .2s;
  }

  /* checked mark aspect changes */
  [type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0) rotate(45deg);
        transform: scale(0) rotate(45deg);
  }

  [type="checkbox"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1) rotate(0);
        transform: scale(1) rotate(0);
  }

  /* Disabled checkbox */
  [type="checkbox"]:disabled:not(:checked) + label:before,
  [type="checkbox"]:disabled:checked + label:before {
    box-shadow: none;
    border-color: #bbb;
    background-color: #e9e9e9;
  }

  [type="checkbox"]:disabled:checked + label:after {
    color: #777;
  }

  [type="checkbox"]:disabled + label {
    color: #aaa;
  }

  /* Accessibility */
  [type="checkbox"]:checked:focus + label:before,
  [type="checkbox"]:not(:checked):focus + label:before {
    box-shadow: inset 0 1px 3px rgba(0,0,0, .1), 0 0 0 6px rgba(78, 78, 78, 0.2);
  }

@media (min-width: $media-min-width) {
    .step-2-section-root {
        margin-top: 80px;
    }

    .header {
        margin-top: 0px;
    } 

    .label {
        font-size: 42px
    }

    .sub-label {
        font-size: 18px;
    }

    .flavor-text { 
        font-size: 16px;
    }

    .disclaimer-text {
        font-size: 11px;
    }

    .header-sub-right {
        font-size: 200px;
    }
    
    

    .cards {
        flex-direction: row;
        column-gap: 20px;
        align-items: center;
        justify-content: space-around;
        box-sizing: border-box;
        padding-bottom: 20px;
    }

    .good-to-go-message-content {
        margin-bottom: 150px;
        padding: 50px;
    }
}
</style>