<template>
  <div class="additional-notes-root">
      <h1>Additional Notes:</h1>
      <h4>Please review our terms and conditions before you continue.</h4>
      <div class="disclaimer-text" v-if="displayDisclaimerText" v-html="$store.state?.dynamicStrings?.terms_and_conditions ?? $store.getters.strings('disclaimer')">
      </div>

      <div class="disclaimer-text-desktop" v-html="$store.state?.dynamicStrings?.terms_and_conditions ?? $store.getters.strings('disclaimer')">
      </div>
      
      <button class="terms-button" v-if="!displayDisclaimerText" @click.prevent="displayDisclaimerText = true">Read Terms and Conditions</button>
      <button class="terms-button" v-if="displayDisclaimerText" @click.prevent="displayDisclaimerText = false">Collapse Terms and Conditions</button>
  </div>
</template>

<script>
export default {
    data() {
        return {
            displayDisclaimerText: false
        }
    }
}
</script>

<style lang="scss" scoped>
.additional-notes-root {
    box-sizing: border-box;
    color: #4D4D4D;
    padding-bottom: 40px;
}

.terms-button {
  box-sizing: border-box;
  height: 32px;
  width: 178px;
  border: 1px solid #0096DB;
  border-radius: 2px;
  background-color: rgba(0,0,0,0.0);
  font-size: 12px;
  font-weight: bold;
  color: #0096DB;
}

.disclaimer-text-desktop {
    font-size: 12px;
    display: none;
}

.disclaimer-text {
    font-size: 12px;
}

@media (min-width: $media-min-width) {
    .disclaimer-text-desktop {
        display: block;
    }
    .terms-button {
        display: none;
    }

    .additional-notes-root {
        padding-bottom: 0px;
    }
}
</style>