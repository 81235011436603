<template>
    <div class="gift-banner-container">
        <div class="gift-banner-text-container">
            <span class="gift-banner-text-header"><b>{{ $store.state?.dynamicStrings?.step_1_section?.header_text }}</b></span>
            <span class="gift-banner-text-text">{{ $store.state?.dynamicStrings?.step_1_section?.sub_header_text }}</span>
        </div>

        <div class="gift-banner-item-container">
            <Step1Gift :imageSrc="require('@/assets/images/gifts/rayban-glasses-500.png')" :headerImageSrc="require('@/assets/images/logos/rayban-logo-150.png')" headerFallbackText="RayBan" footerText="Meta RayBans" />
            <Step1Gift :imageSrc="require('@/assets/images/gifts/meta-quest-500.png')" :headerImageSrc="require('@/assets/images/logos/meta-logo-150.png')" headerFallbackText="Meta" footerText="Quest VR Headset" />
            <Step1Gift :imageSrc="require('@/assets/images/gifts/macbook-air-500.png')" :headerImageSrc="require('@/assets/images/logos/apple-logo-150.png')" headerFallbackText="Apple" footerText="MacBook Air" />
        </div>
    </div>
  <div class="step-1-section-root" id="step-1-scroll-anchor" style="display: flex; justify-content: center; align-items: center;">
    
      <div class="header">
          <div class="header-sub-left">
                  <div class="label">{{ $store.state?.dynamicStrings?.step_1_section?.header_2_text }}</div>
                  <div class="sub-label">{{ $store.state?.dynamicStrings?.step_1_section?.main_text_1 }}</div>

                  <div v-html="$store.state?.dynamicStrings?.step_1_section?.main_text_2"></div>

                  <div style="color: white; font-size: 16px; font-weight: bold;">{{ $store.state?.dynamicStrings?.step_1_section?.footer }}</div>
              </div>
            <div class="header-sub-right">1</div>
      </div>

        <div class="cards" v-if="!isOnHighestSMRMPackage">
              <PackageSectionCard :cardPackage="packages.socialDominate" @click="packageSelected(packages.socialDominate)"/>
                <div class="reputation-card-section" v-if="!isOnHighestRMPackage">
                    <span v-show="showPickOne">Choose One</span>
                    <div class="reputation-cards">
                        <PackageSectionCard :cardPackage="packages.reputationDefend" @click="packageSelected(packages.reputationDefend)"/>
                        <PackageSectionCard :cardPackage="packages.reputationEngage" @click="packageSelected(packages.reputationEngage)"/>
                    </div>
                </div>
          </div>
          <div class="card" style="max-width: 600px; margin-top: 10px; height: 500px; display: flex; justify-content: center; align-items: center; text-align: center; color: #2b2b2b;" v-else>
            <div class="step-1-good-to-go-card-content">Looks like you are already good to go here!</div>
        </div>
  </div>
</template>

<script>
import Step1Gift from '@/components/reusable/Step1Gift.vue'
import PackageSectionCard from '@/components/PackageSectionCard.vue'
import GiftCard from '@/components/reusable/GiftCard.vue'
import { mapGetters } from 'vuex';

export default {
    components: {
        PackageSectionCard,
        GiftCard,
        Step1Gift
    },
    computed: {
        ...mapGetters(['isOnHighestSMRMPackage', 'isOnHighestRMPackage']),
        packages() {
            return this.$store.state.packages;
        },
        showPickOne() {
            return this.$store.state.packagesToDisplay.filter(pkg => pkg.name.toUpperCase().includes("REPUTATION")).length > 1;
        }
    },
    methods: {
        packageSelected(pkg) {
            // this.$store.dispatch("sendFullStoryEvent", { 
            //     FS: this.$FullStory,
            //     eventName: "SMRM-PackageSelected",
            //     eventPayload: {
            //         package: pkg.name
            //     }
            // });

            this.$store.commit('TOGGLE_GM_PACKAGE', pkg)
        },
    }
}
</script>

<style lang="scss" scoped>
.step-1-section-root {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 90%;
    padding-bottom: 35px;
}

.step-1-good-to-go-card-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 5px solid #0096db;
    // background-color: white;
    // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    padding: 20px;
    color: rgb(63, 63, 63);
    font-size: 36px; 
}

.gift-banner-text-container {
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.gift-banner-item-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    column-gap: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.gift-banner-text-header {
    font-size: 42px;
}

.gift-banner-text-text {
    font-size: 12px;
}

.gift-banner-container {
    right: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    column-gap: 15px;
}

#apple-gift-card {
    padding-top: 15px;
    padding-bottom: 15px;
}

#gift-3 {
    padding-right: 11px;
    padding-left: 11px;
}

.header {
    display: flex;
}

.header-sub-left {
    display: flex;
    flex-direction: column;
    width: 70%;
    row-gap: 10px;
}

.header-sub-right {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 30%;
    color: white;
    text-align: right;
    font-size: 150px;
    opacity: 0.5;
    font-weight: bold;
}

.label {
    font-size: 26px;
    font-weight: bold;
    color: white;
}

.sub-label {
    color: #FFF;
    font-weight: bold;
}

// .content {
//     display: flex;
//     flex-direction: column;
//     width: 100%;
//     height: 100%;
// }

.cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: max-content;
    row-gap: 25px;
    margin-top: 25px;
}

.reputation-card-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.reputation-card-section > span {
    color: white;
    font-weight: 1rem;
    font-weight: bold;
    margin-bottom: 15px;
}

.reputation-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: max-content;
    row-gap: 25px;
}


// .cards > div {
//     flex: 1;
// }

@media (min-width: $media-min-width) {
    .step-1-section-root {
        padding-bottom: 0px;
    }

    .gift-banner-text-container {
        color: white;
        text-align: left;
        display: flex;
        flex-direction: column;
        padding-left: 100px;
        width: 30%;
    }

    .gift-banner-item-container {
    margin-top: 0px;
    margin-bottom: 0px;
}

    .gift-banner-text-header {
        font-size: 36px;
    }

    .gift-banner-text-text {
        font-size: 16px;
        font-style: italic;
    }

    .gift-banner-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-right: 50px;
    }

    .gift-banner-image-container {
        width: 130px;
        height: 130px;
        background-color: white;
        border: 2px solid #FFA500;
        border-radius: 50%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .gift-banner-overlay-image {
        width: 100%; /* or set to a custom size */
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(1.1);
    }

#apple-gift-card {
    padding-top: 20px;
    padding-bottom: 20px;
}

#gift-3 {
    padding-right: 13px;
    padding-left: 13px;
}

    .cards {
        flex-direction: row;
        column-gap: 20px;
        align-items: center;
        justify-content: space-around;
        box-sizing: border-box;
        // margin-top: 250px;
        // transform: translateY(-230px);
    }

    .reputation-card-section {
        min-width: 65%;
    }

    .reputation-cards {
        flex-direction: row;
        column-gap: 20px;
        align-items: center;
        justify-content: space-around;
        box-sizing: border-box;
        margin-top: 0px;
    }

    // .cards > div:nth-child(3) {
    //     order: 1;
    // }

    // .cards > div:nth-child(1) {
    //     order: 2;
    // }

    // .cards > div:nth-child(2) {
    //     order: 3;
    // }    
    

    .label {
        font-size: 42px
    }

    .header-sub-right {
        font-size: 200px;
    }

    .step-1-good-to-go-card-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 5px solid #0096db;
    padding: 50px;
    margin-bottom: 110px;
    color: #2b2b2b;
}
}
</style>