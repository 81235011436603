<template>
  <div class="outer-container">
    <div class="grid-container">
      <div class="backdrop"></div>

      <div class="logo"><img src="@/assets/images/das-logo.png" alt="Digital Air Strike"></div>

      <div class="welcome-text">
        <!-- <img src="@/assets/images/gm-logo.png" alt="General Motors" style="max-width: 100px;"> -->
        <img src="@/assets/images/welcome-banner-800.png" alt="General Motors" style="width: 100%;">
        <img src="@/assets/images/welcome-blurb-1-800.png" alt="General Motors" style="width: 100%;">
        <!-- <div v-html="$store.getters.strings('welcome_text')"></div> -->
        <!-- Welcome General Motors<br>Standards for Excellence Dealers! -->
         

      </div>

      <!-- <div class="welcome-text-date">{{welcomeDateText}}</div> -->

      <div class="input">
        <div class="form-container">
          <WelcomeSectionForm />
        </div>
      </div>
      
      <div class="welcome-text-2">
        <div class="welcome-text-2-container">

          <span class="welcome-text-header-text"> {{ $store.getters.dynamicStrings('welcome_text_2', 'header_text') }}</span>

          <div style="font-size: 16px; margin-top: 10px;">
            <span v-html="$store.getters.dynamicStrings('welcome_text_2', 'list_header_text')"></span>

            <ul class="welcome-text-list">
              <li v-for="(item, index) in $store.getters.dynamicStrings('welcome_text_2', 'list_items')" :key="index">{{ item }}</li>
            </ul>

            <span>{{ $store.getters.dynamicStrings('welcome_text_2', 'footer_text') }}</span>
          </div>

          <button class="start-button m-grouping" @click="scrollDown">Start!</button>
        </div>
      </div>
    </div>

    <div class="sfe-images">
      <img src="@/assets/images/dds-logo.png" alt="DDS" style="width: 50%; image-rendering: auto; max-width: 200px; object-fit: contain; ">
      <img src="@/assets/images/as-seen-banner.png" alt="As seen on Forbes, The New York Times, Automitive News, and USA Today!" style="width: 100%; object-fit: contain; max-width: 750px;">
    </div>
  </div>
</template>

<script>
import WelcomeSectionForm from './WelcomeSectionForm.vue'

export default {
  name: 'WelcomeSection',
  components: {
    WelcomeSectionForm
  },
  computed: {
    welcomeDateText() {
      const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      const d = new Date()
      return `${monthNames[d.getMonth()].toUpperCase()} ${d.getFullYear()} | DIGITAL AIR STRIKE`;
    }
  },
  methods: {
    scrollDown() {
      // this.$store.dispatch("sendFullStoryEvent", { FS: this.$FullStory, eventName: "SFE-StartClicked" });

      var element = document.getElementById("start-anchor");
      element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }
  }
}
</script>

<style lang="scss" scoped>
  .outer-container {
    margin-bottom: 25px;
  }
  
.grid-container {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(12, minmax(0px, max-content));
  grid-template-rows: repeat(12, minmax(0px, max-content));
  grid-auto-flow: row;
  gap: 15px;
}

.welcome-text-header-text {
  font-size: 16px;
  font-weight: 700;
  line-height: 20.8px;
}

.welcome-text-list {
  list-style-type: none;
  padding-left: 0;
  padding-top: 0px;
  font-size: 16px;
  margin-block-start: 0px;
}

.welcome-text-list li {
  position: relative;
  padding-left: 20px;
}

.welcome-text-list li::before {
  content: '';
  background-image: url('~@/assets/images/checkmark.png');
  background-size: 16px 16px;
  background-repeat: no-repeat;
  display: inline-block;
  width: 16px;
  height: 16px;
  position: absolute;
  left: 0;
  top: 4px;
}

.backdrop { 
  grid-area: 1 / 1 / 10 / 13;
  //background-image: url('~@/assets/images/welcome-container-bg.png');
  background: #073980;
  background-repeat: no-repeat;
  background-size: cover;
}

.input { 
  display: flex;
  grid-area: 4 / 1 / 11 / 13;
  justify-content: center;
  align-items: flex-start;
}

.logo { 
  grid-area: 1 / 1 / 2 / 13;
  display: flex;
  justify-content: center;
}

.logo > img {
  width: 50%;
  min-width: 300px;
  max-width: 400px;
  margin-top: 10px;
  object-fit: cover;
}

.welcome-text { 
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 2 / 1 / 3 / 13;
  color: white;
  font-size: clamp(26px, 2.50vw, 29px);
  font-weight: bold;
  justify-content: center;
  text-align: center;
  row-gap: 15px;
}

.welcome-text-date { 
  display: grid;
  grid-area: 3 / 1 / 4 / 13;
  justify-content: center;
  color: white;
}

.form-container {
  width: 85%;
  height: calc(100% + 25px);
  display: flex;
  justify-content: center;
}

.welcome-text-2 { 
  display: grid;
  grid-area: 11 / 1 / 13 / 13;
  justify-items: center;
}

.welcome-text-2-container {
  font-size: 11px;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.sfe-images {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 20px;
  margin-top: 40px;
  margin-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
}

.sfe-images > img {

}

.start-button {
  height: 56px;
  width: 124px;
  border-radius: 4px;
  background-color: #0096DB;
  color: white;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  border: none;
  cursor: pointer;
}

@media (min-width: $media-min-width) {
  .grid-container {
    max-width: 1024px;
    max-height: 750px;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(24, 1fr);
    gap: 0px;
  }

  .backdrop { 
    grid-area: 1 / 1 / 24 / 13;
  }

  .logo { 
    grid-area: 1 / 1 / 3 / 13;
    justify-content: flex-start;
    margin-left: 20px;
    margin-top: 10px;
  }

  .input { 
    grid-area: 3 / 7 / 25 / 13;
  }

  .form-container {
    width: 90%;
    height: 100%;
  }

  .welcome-text { 
    grid-area: 3 / 1 / 10 / 7;
    align-items: flex-start;
    text-align: left;
    justify-content: left;
    padding-left: 10%;
  }

  .welcome-text-date { 
    grid-area: 12 / 1 / 13 / 7;
    justify-content: left;
    padding-left: 10%;
    font-weight: 00;
  }

  .welcome-text-2 { 
    grid-area: 11 / 1 / 24 / 7;
    justify-content: left;
    padding-left: 10%;
  }

  .welcome-text-2-container {
    display: block;
    color: white;
    width: 100%;
    justify-content: left;
  }

  .welcome-text-2-container > button {
    margin-top: 20px;
  }

  .sfe-images {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: $media-min-width) and (max-width: 909px) {
  .backdrop { 
    grid-area: 1 / 1 / 25 / 13;
  }

  .welcome-text-2 { 
    grid-area: 10 / 1 / 24 / 7;
  }
}

@media (min-width: $media-min-width) and (max-width: 800px) {
  .welcome-text-2 { 
    grid-area: 9 / 1 / 24 / 7;
  }
}
</style>